import * as Sentry from '@sentry/nextjs';

import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  API_VERSION,
  getRequest,
  NEXT_PUBLIC_API_URL,
} from '../../api/wagtail';
import { CONTENT_TYPES } from '../../consts/content-types';
import initDateRangeState from '../../consts/initDateRangeState';
import { NOT_COVERED_TITLE_PERCENTS } from '../../consts/style-consts';
import { DDNC_NEWS_URL, FULL_SEARCH_ROUTES } from '../../consts/urls';
import { kebabToTitle } from '../../utils/caseconverters';
import { IS_NEXT_EXPORT } from '../../utils/export';
import { matchUrl } from '../../utils/matchUrl';
import removeDomainFromUrl from '../../utils/removeDomainFromUrl';
import removeQueryParameters from '../../utils/removeQueryParameters';

const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const router = useRouter();
  const initialTag = matchUrl(router.asPath, [DDNC_NEWS_URL])
    ? 'news'
    : matchUrl(router.asPath, FULL_SEARCH_ROUTES)
    ? kebabToTitle(removeQueryParameters(router.asPath).split('/')[1])
    : null;
  // search
  const [isSearchActive, _setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchContentType, _setSearchContentType] = useState(
    CONTENT_TYPES[initialTag],
  );
  const [searchContentName, _setSearchContentName] = useState('');

  const [subcategoryFilterValue, setSubcategoryFilterValue] = useState(null);
  const [isSubcategoryFilterActive, setIsSubcategoryFilterActive] =
    useState(true);

  // header
  const [headerRef, setHeaderRef] = useState(null);
  const [percentOfCoveredTitle, setPercentOfCoveredTitle] = useState(1);

  // news and reports
  const [newsItems, setNewsItems] = useState([]);
  const [reportItems, setReportItems] = useState([]);
  const [dateRangeState, setDateRangeState] = useState([initDateRangeState]);
  const [isItemsLoading, setItemsLoading] = useState(false);

  // log-in
  const [user, setUser] = useState(null);
  const [isLoginFormActive, setIsLoginFormActive] = useState(false);

  const [isSubscribeRequestIsSent, setSubscribeRequestIsSent] = useState(false);

  // navigation
  const [isNavActive, setIsNavActive] = useState(false);
  const [navItems, setNavItems] = useState(null);

  // gtm
  const [contentView, setContentView] = useState('notContent'); // "gated", "full" and "notContent"
  const setSearchContentType = (tag) => {
    _setSearchContentType(CONTENT_TYPES[tag]);
    _setSearchContentName(tag);
  };

  const setIsSearchActive = (value) => {
    _setIsSearchActive(value);

    if (value) {
      setPercentOfCoveredTitle(NOT_COVERED_TITLE_PERCENTS);
    }
  };

  useEffect(() => {
    if (IS_NEXT_EXPORT) {
      return;
    }

    const retrieveAndSetUserProfile = async () => {
      window.dataLayer = window.dataLayer || [];

      try {
        const { json: fetchedUser } = await getRequest(
          `${removeDomainFromUrl(NEXT_PUBLIC_API_URL)}/${API_VERSION}/profile/`,
        );

        if (fetchedUser.isLoggedIn) {
          const userJson = window.localStorage.getItem('user');
          if (!userJson) {
            window.localStorage.setItem('user', JSON.stringify(fetchedUser));
          }

          window.dataLayer.push({
            userId: fetchedUser.userId,
            email: fetchedUser.email,
            subscriptionType: fetchedUser.userLevel,
            contentView,
            userIsAuthenticated: true,
            subscriptionId: fetchedUser.userMetadata.subscriptionId,
            event: 'login',
            ...fetchedUser,
          });

          setUser(fetchedUser);

          Sentry.addBreadcrumb({
            category: 'auth',
            message: `Authenticated user ${fetchedUser.email}`,
            level: 'info',
            data: fetchedUser,
          });

          Sentry.setUser({
            email: fetchedUser.email,
            username: fetchedUser.givenName,
            id: fetchedUser.userId,
            userLevel: fetchedUser.userLevel,
            userMetadata: fetchedUser.userMetadata,
          });
        } else {
          window.localStorage.removeItem('user');

          window.dataLayer.push({
            userId: 'anonymous',
            email: undefined,
            userIsAuthenticated: false,
            subscriptionId: undefined,
            event: 'logout',
          });

          setUser(null);
        }
      } catch (e) {
        console.error('Login error - ', e);
      }
    };

    retrieveAndSetUserProfile();
  }, []);

  const sharedState = {
    isSearchActive,
    setIsSearchActive,
    searchValue,
    setSearchValue,
    searchContentType,
    setSearchContentType,
    searchContentName,
    headerRef,
    setHeaderRef,
    percentOfCoveredTitle,
    setPercentOfCoveredTitle,
    newsItems,
    reportItems,
    setNewsItems,
    setReportItems,
    dateRangeState,
    setDateRangeState,
    isItemsLoading,
    setItemsLoading,
    user,
    setUser,
    isLogged: !!user,
    isNavActive,
    setIsNavActive,
    isLoginFormActive,
    setIsLoginFormActive,
    navItems,
    setNavItems,
    subcategoryFilterValue,
    setSubcategoryFilterValue,
    isSubcategoryFilterActive,
    setIsSubcategoryFilterActive,
    contentView,
    setContentView,
    isSubscribeRequestIsSent,
    setSubscribeRequestIsSent,
  };

  return (
    <GlobalContext.Provider value={sharedState}>
      {children}
    </GlobalContext.Provider>
  );
};

export function useGlobalContext() {
  return useContext(GlobalContext);
}
