const imageGalleryStyles = `
.image-gallery-content.top.fullscreen {
  .image-gallery-slide.center {
    height: calc(100vh - 72px);
    display: flex !important;
    justify-content: center;
    align-items: center;

    img {
      height: auto;
      width: auto;
      object-fit: contain;
    }
  }
}

.image-gallery-thumbnail {
    position: relative;
    display: inline-block;
    width: 50px !important;
    margin-right: 5px;
    margin-bottom: 5px;
    border: none;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 1;
    }

    &.active,
    &:focus {
      outline: none;
      border: none;

      &::before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 1;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        outline: none;
        border: none;

        @media (max-width: 768px) {
          border: none;
        }
      }
    }
  }

  .image-gallery-thumbnails .image-gallery-thumbnails-container{
    text-align: left !important;
  }`;

export default imageGalleryStyles;
